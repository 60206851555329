import { ReactComponent as ErrorIcon } from '../../../icons/error.svg'
import { ErrorBoxStyle } from "../style"

export default function ErrorPopup({message, setMessage}) {
    const style = ErrorBoxStyle()

    const app = document.getElementById('app-container')
    app.style.overflow = 'hidden'
    app.style.height = '100vh'

    const closeSpecifications = () => {
        app.style.overflow = 'auto'
        app.style.height = null
        setMessage("")
    }
    
    return(
        <div style={style.background}>
            <div style={style.container}>
                <ErrorIcon style={style.icon} />
                <div>
                    <div style={style.title}>Payment Error</div>
                    <p style={style.text}>{ message }</p>
                </div>
                <button style={style.button} onClick={closeSpecifications}>Close</button>
            </div>
        </div>
    )
}