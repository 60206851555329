export const THEMES = {
    "white-theme": {
        "headerBackground": "#ffffff",
        "headerText": "#171717",

        "pageBackground": "#ffffff",
        "pageHeader": "#3e3e3e",
        "pageText": "#4f4f4f",
        
        "componentBackground": "#eeeeee",
        "componentHeader": "#171717",
        "componentText": "#3e3e3e",

        "previewImageBackground": "#eeeeee", // Usually the same as component background
        
        "buttonBackground": "#008a00",
        "buttonText": "#f2f3f4",

        "specialBackground": "#008a00",
        "specialText": "#f2f3f4",
        "errorText": "#be0000",

        "footerBackground": "#eeeeee",
        "footerFont": "#282828"
    },
    "whwhbk": {
        "headerBackgroundColor": "#ffffff",
        "headerTextColor": "#000000",
        "headerHoverColor": "darkgray",
        "borderColor": "#dddddd",
        "backgroundColorA": "#ffffff",
        "backgroundColorB": "#f6f6f6",
        "fontColorA": "#333333",
        "subFontColorA": "#666666",
        "fontColorB": "#111111",
        "subFontColorB": "#333333",
        "hoverColor": "lightgray",
        "accessoryColor": "#008a00",
        "accessoryTextColor": "#ffffff",
        "linkColor": "#4d4db3",
        "errorColor": "#9a3334",
        "starColor": "#008a00",
        "footerBackgroundColor": "#eeeeee",
        "footerFontColorA": "#282828",
        "footerFontColorB": "#444444",
        "footerHoverColor": "darkgray"
    },
    "bkbkwh": {
        "headerBackground": "blue",
        "headerText": "pink",

        "pageBackground": "darkgreen",
        "pageHeader": "green",
        "pageText": "lightgreen",
        
        "componentBackground": "darkred",
        "componentHeader": "red",
        "componentText": "lightpink",
        
        "buttonBackground": "black",
        "buttonText": "yellow",

        "specialBackground": "purple",
        "specialText": "yellow",
        "errorText": "royalblue",

        "footerBackground": "purple",
        "footerFont": "yellow"
    },
    "backup": {
        "headerBackground": "#212a31",
        "headerText": "#dddddd",
        "headerHover": "white",

        "pageBackground": "#424d55",
        "pageHeader": "#f8f8f8",
        "pageText": "#d8d8d8",
        
        "componentBackground": "#212a31",
        "componentHeader": "green",
        "componentText": "lightgreen",
        
        "buttonBackground": "blue",
        "buttonText": "#ffffff",

        "specialBackground": "purple",
        "specialText": "yellow",
        "errorText": "brown",

        "footerBackground": "#111111",
        "footerFont": "#d8d8d8"
    }
}