import { useContext } from 'react';

// Sections
import Header from './components/header';
import { Reviews } from './components/reviews';
import { AboutUs } from './components/aboutUs';
import { Footer } from '../../components/footer';
import Hero from './components/hero/index';
import Carousel from './components/carousel/index';
import Feature from './components/feature';
import { Helmet } from 'react-helmet-async';
import { websiteURL } from '../../config.mjs';
import { ProductsContext } from '../../providers/productsProvider';

// ----------------------------------------------------------------------

export default function LandingPage() {    
    const products = useContext(ProductsContext)
    const landing = products.landing

    return (
        <>
            <Helmet>
                <title>{landing.websiteTitle}</title>
                <meta name="description" data-rh="true" content={landing.websiteDescription} />
                <link rel="canonical" href={websiteURL} />
            </Helmet>
            <Header landing={landing} />
            { landing.sections.map((section, id) => {
                switch(section.type) {
                    case "hero": return <Hero landingData={section} key={id} />
                    case "carousel": return <Carousel landingData={section} key={id} />
                    case "feature": return <Feature landingData={section} key={id} />
                    case "reviews": return <Reviews landingData={section} key={id} />
                    case "about": return <AboutUs id={section.headerLinkID} landingData={section} key={id} />
                    default: return null
                }
            })}
            <Footer landingFooter />
        </>
    );
}