import { useParams, Outlet } from 'react-router-dom';
import LandingPage from '../pages/landing';
import CartPage from '../pages/cart';
import CheckoutPage from '../pages/checkout';
import FAQPage from '../pages/faq';
import ContactUsPage from '../pages/contact';
import CompletedOrder from '../pages/completed';

export default function LandingPath() {
    const { categoriesID } = useParams()

    function getOutlet() {
        if(categoriesID) {
            if(categoriesID.toLowerCase() === "shopping-cart") return <CartPage />
            else if(categoriesID.toLowerCase() === "checkout") return <CheckoutPage />
            else if(categoriesID.toLowerCase() === "completed") return <CompletedOrder />
            else if(categoriesID.toLowerCase() === "faq") return <FAQPage />
            else if(categoriesID.toLowerCase() === "contact") return <ContactUsPage />
            else return <Outlet />
        }
        return <LandingPage />
    }
    
    return getOutlet()
}