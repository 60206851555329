import { useQuery } from "@tanstack/react-query"
import { HeaderStyle } from "../style"

export function PrecheckHeader({ vehicle, product }) {
    const style = HeaderStyle()
    
    const api = `https://shearcomfort-9e1af9.herokuapp.com/vehicle/image?year=${vehicle.year}&make=${vehicle.make}&model=${vehicle.model}`
    const title = vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model

    const getVehiclePicture = async() => {
        return await fetch(api)
            .then(response => response.json())
    }
    
    const {data} = useQuery({
        queryKey: ["vehiclePicture", api],
        queryFn: getVehiclePicture
    })
    
    return (
        <div style={style.header}>
            <img src={data?.url || '/img/common/no-vehicle.jpg'} alt={title} style={style.vehiclePicture} />
            <div>
                <div style={style.title}>{title}</div>
                <div style={style.descriptors}>{product.name} {product.type}</div>
            </div>
        </div>
    )
}