import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { THEMES } from "../../data/themes";
import { defaultTheme } from "../../config.mjs";

export function ContainerStyle() { 
    return {
        desktopContainer: {
            display: "flex",
            gap: "24px",
            width: "100%",
            maxWidth: "1700px",
            padding: "16px 4vw",
            justifyContent: "center"
        },
        mobileContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "16px 4vw",
            justifyContent: "center"
        },
        leftContainer: {
            display: "flex",
            flexDirection:"column",
            width: "100%"
        },
        rightContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "500px",
            minWidth: window.innerWidth < 1024 ? "280px" : "360px",
            
        }
    }
}

export function DescriptionStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)

    return {
        container: {
            width: "100%",
            textAlign: "left",
            padding: fonts.mobile ? "16px 32px 32px" : "0 0 16px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            borderBottom: fonts.mobile ? null : `1px solid ${theme.pageText}`
        },
        product: {
            fontSize: window.innerWidth < 1160 ? "14px" : "16px",
            fontWeight: "400",
            lineHeight: window.innerWidth < 1160 ? "16px" : "20px",
            color: theme.pageText
        },
        title: {
            fontSize: window.innerWidth < 1160 ? "22px" : "28px",
            fontWeight: "700",
            fontFamily: "'Josefin Sans', sans-serif",
            letterSpacing: ".02em",
            textTransform: "uppercase",
            color: theme.pageHeader
        },
        reviews: {
            fontSize: "14px", 
            marginLeft: "16px",
            color: theme.pageHeader
        },
        price: {
            display: "flex",
            alignItems: "center",
            fontSize: window.innerWidth < 1160 ? "20px" : "28px",
            fontWeight: "600",
            color: theme.pageHeader
        },
        oldPrice: {
            marginLeft: "14px",
            fontSize: window.innerWidth < 1160 ? "16px" : "20px",
            fontWeight: "400",
            textDecoration: "line-through",
            opacity: ".56"
        },
        starContainer: {
            display: "flex",
            alignItems: "center",
            gap: "2px"
        },
        discount: {
            fontSize: window.innerWidth < 1160 ? "12px" : "14px",
            fontWeight: "400",
            lineHeight: window.innerWidth < 1160 ? "16px" : "18px",
            color: theme.errorText
        }
    }
}

export function PreviewStyle() { 
    const fonts = useContext(FontContext)
    const theme = THEMES[defaultTheme]
    
    return {
        container: {
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            gap: "24px",
            width: "100%",
            maxWidth: "1700px",
            padding: "16px 4vw",
            justifyContent: "center"
        },
        outer: {
            display: "flex",
            flexDirection:"column",
            width: "100%"
        },
        previewContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            gap: "24px",
            flexDirection: window.innerWidth <= 1200 ? "column-reverse" : "row",
            justifyContent: window.innerWidth <= 1200 ? "flex-end" : "flex-start"
        },
        carousel: {
            width: window.innerWidth <= 1200 ? "100%" : "15%",
            height: window.innerWidth <= 1200 ? fonts.mobile ? "80px" : "120px" : "100%",
            display: "flex",
            flexDirection: window.innerWidth <= 1200 ? "row" : "column",
            justifyContent: window.innerWidth <= 1200 ? "center" : null,
            gap: "2vw"
        },
        preview: {
            backgroundColor: theme.previewImageBackground,
            width: window.innerWidth <= 1200 ? "100%" : "85%",
            height: window.innerWidth <= 1200 ? "auto" : "100%",
            maxWidth: "800px",
            maxHeight: "800px"
        },
        previewImage: {
            maxWidth: "100%",
            maxHeight: "100%"
        },
        previewButton: {
            backgroundColor: theme.previewImageBackground,
            width: window.innerWidth <= 1200 ? "120px" : "100%",
            height: window.innerWidth <= 1200 ? "100%" : "8vw",
            cursor: "pointer"
        },
        carouselImage: {
            maxHeight: "100%",
            maxWidth: "100%"
        },
        infoContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: fonts.mobile ? null : "500px",
            minWidth: window.innerWidth < 1024 ? "280px" : "360px",
            
        }
    }
}

export function DetailsStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        outer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "60px 0"
        },
        container: {
            width: "100%",
            maxWidth: "1148px",
            display: "flex",
            flexDirection: fonts.mobile ? "column-reverse" : "row",
            padding: "0 20px",
            gap: "60px"
        },
        piece: {
            flex: "1 0"
        },
        mini: {
            display: "flex",
            flexDirection: "column",
            gap: "16px"
        },
        header: {
            ...fonts.h3,
            color: theme.pageHeader
        },
        liContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            padding: "6px"
        },
        text: {
            fontSize: window.innerWidth < 1340 ? "14px" : "16px",
            color: theme.pageText
        },
        liText: {
            paddingLeft: "12px",
            fontSize: window.innerWidth < 1340 ? "14px" : "16px",
            color: theme.pageText
        }
    }
}

export function ColorOptionStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            padding: fonts.mobile ? "24px 4vw" : window.innerWidth < 1160 ? "24px 0" : "32px 0",
            borderBottom: `1px solid ${theme.pageText}`
        },
        title: {
            fontSize: window.innerWidth < 1160 ? "14px" : "16px",
            lineHeight: window.innerWidth < 1160 ? "20px" : "22px",
            fontWeight: "600",
            color: theme.pageText
        },
        optionContainer: {
            display: "flex",
            gap: "4px",
            flexWrap: "wrap",
            cursor: "pointer",
            marginTop: "12px",
            justifyContent: fonts.mobile ? "center" : "flex-start"
        },
        optionBorder: {
            borderStyle: "solid",
            borderWidth: "2px",
            borderRadius: "12px",
            transitionDuration: ".2s"
        },
        option: {
            width: window.innerWidth > 1500 ? "80px" : window.innerWidth < 1160 && !fonts.mobile ? "40px" : "60px",
            height: window.innerWidth > 1500 ? "80px" : window.innerWidth < 1160 && !fonts.mobile ? "40px" : "60px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `2px solid ${theme.pageBackground}`
        }
    }
}

export function VehicleStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: fonts.mobile ? "24px 4vw" : window.innerWidth < 1160 ? "24px 0" : "32px 0",
            borderBottom: `1px solid ${theme.pageText}`
        },
        title: {
            fontSize: window.innerWidth < 1160 ? "14px" : "16px",
            lineHeight: window.innerWidth < 1160 ? "20px" : "22px",
            fontWeight: "600",
            color: theme.pageText
        },
        yearMakeContainer: {
            display: "flex",
            height: "40px",
            gap: "16px"
        },
        year: {
            flexGrow: "1"
        },
        make: {
            flexGrow: "2"
        },
        model: {
            marginTop: "-8px"
        },
        item: {
            minHeight: "24px",
            fontSize: window.innerWidth < 1160 ? "16px" : "18px",
            padding: "0",
            borderRadius: "0",
            border: `1px solid ${theme.pageText}`
        }
    }
}

export function FileproOptionsStyle() { 
    const fonts = useContext(FontContext)
    const theme = THEMES[defaultTheme]

    return {
        container: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: fonts.mobile ? "24px 4vw" : "24px 0",
            borderBottom: `1px solid ${theme.pageText}`
        },
        title: {
            fontSize: window.innerWidth < 1160 ? "14px" : "16px",
            lineHeight: window.innerWidth < 1160 ? "20px" : "22px",
            fontWeight: "600",
            color: theme.pageText
        },
        contentContainer: {
            minHeight: "110px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "space-between"
        },
        subTitle: {
            ...fonts.standard,
            fontWeight: "500"
        },
        unavailableTitle: {
            ...fonts.standard,
            fontWeight: "600",
            color: theme.errorText,
            width: "100%"
        },
        unavailableTextContainer: {
            ...fonts.small,
            color: theme.pageText
        }
    }
}

export function FileproOptionRowsStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        title: {
            fontSize: window.innerWidth < 1160 ? "14px" : "16px",
            lineHeight: window.innerWidth < 1160 ? "20px" : "22px",
            fontWeight: "600",
            color: theme.pageText
        },
        rowContainer: {
            display: "flex",
            flexWrap: "wrap",
            gap: "6px 12px",
            width: "100%"
        },
        rowBorder: {
            padding: "4px",
            borderRadius: "8px",
            transitionDuration: ".4s",
            flex: "1",
            minWidth: "calc(50% - 12px)",
        },
        row: {
            textAlign: "center",
            borderStyle: "solid",
            padding: "8px ",
            borderRadius: "8px",
            borderWidth: "1px",
            cursor: "pointer",
            position: "relative",
            backgroundColor: "#ffffff"
        },
        checkmark: {
            position: "absolute",
            top: "4px",
            left: "4px",
            width: "20px",
            height: "20px",
            fill: theme.specialBackground,
            transitionDuration: ".4s"
        },
        text: {
            fontSize: fonts.mobile ? "12px" : "14px", 
            lineHeight: fonts.mobile ? "16px" : "20px",
            fontWeight: "600",
            color: "#333333",
            width: "100%",
            textAlign: "center"
        }
    }
}

export function ButtonStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            ...fonts.h3,
            width: "100%",
            height: "60px", //48
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
            cursor: "pointer",
            transitionDuration: ".3s",
            textTransform: "uppercase",
            marginTop: window.innerWidth < 1160 ? "24px" : "32px",
        },
        good: {
            backgroundColor: theme.buttonBackground,
            color: theme.buttonText,
            cursor: "pointer",
            border: "0"
        },
        bad: {
            backgroundColor: theme.pageText,
            color: theme.pageBackground,
            opacity: ".35",
            cursor: "default"
        }
    }
}