import { ModalStyle } from "../style"

export function Modal({ children, closeModal }) {
    const style = ModalStyle()

    const closeSpecifications = (event) => {
        if(event.target.id === 'spec-outer') closeModal()
    }
    
    return(
        <div style={style.background} id="spec-outer" onClick={closeSpecifications}>
            <div style={style.container}>
                { children }
            </div>
        </div>
    )
}