import { TitleStyle } from "../style"

export function LineTitle({ title }) {
    const style = TitleStyle()
    
    return (
        <h1 style={style.container}>
            {title}
        </h1>
    )
}