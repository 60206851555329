import { CarouselInfo } from './info';
import CarouselComponent from './carousel';
import { LandingComponent } from '../../style';
import { useContext } from 'react';
import { ProductsContext } from '../../../../providers/productsProvider';

export default function Carousel({ landingData }) {
    const style = LandingComponent(landingData)
    const products = useContext(ProductsContext)
    let data = []

    landingData.products.forEach((prod) => {
        for(var i = 0; i < products.lines.length; i++) {
            if(products.lines[i].siteID === prod.lineName) {
                products.lines[i].products.forEach((pr) => {
                    if(pr.siteID === prod.product) data.push({prodLine: prod.lineName, image: prod.image, info: pr})
                })
            }
        }
    })

    return (
        <div style={style.container} className='carousel' id={landingData.headerLinkID || ''} >
            <div style={style.innerContainer}>
                <CarouselInfo landingData={landingData} />
                <CarouselComponent data={data} />
            </div>
        </div>
    )
}