import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { THEMES } from "../../data/themes";
import { defaultTheme } from "../../config.mjs";

export function OuterStyle() {
    return {
        outer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            maxWidth: "1700px", 
            padding: "18px 4vw",
            gap: "40px"
        },
        container: {
            width: "100%", 
            maxWidth: "1700px", 
            padding: "18px 4vw",
            display: "flex",
            flexDirection: "column",
            gap: "24px"
        },
        individualContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "8px"
        },
        inner: {
            display: "flex",
            flexWrap: "wrap",
            gap: "24px"
        }
    }
}

export function TitleStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            ...fonts.h2,
            fontWeight: "400",
            width: "100%",
            color: theme.pageHeader,
            textTransform: "uppercase",
            textAlign: "center",
            padding: "0 0 24px"
        },
        individualContainer: {
            ...fonts.h2,
            fontWeight: "400",
            width: "100%",
            color: theme.pageHeader,
            textTransform: "uppercase",
            textAlign: 'left', 
            textDecoration: 'underline'
        }
    }
}

export function CategoryStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            minHeight: window.innerWidth < 500 ? "" : "340px",
            width: "100%",
            maxWidth: "400px",
            backgroundColor: theme.componentBackground,
            textDecoration: "none",
            transitionDuration: ".3s"
        },
        textContainer: {
            margin: "24px 16px",
            transitionDuration: ".3s"
        },
        header: {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            color: theme.componentHeader,
            transitionDuration: ".3s"
        },
        subText: {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "20px",
            color: theme.componentText,
            transitionDuration: ".3s"
        },
        imageContainer: {
            padding: fonts.mobile ? "0" : "8px",
            transitionDuration: ".3s"
        },
        image: {
            width: "100%",
            height: "auto",
            transitionDuration: ".3s"
        }
    }
}