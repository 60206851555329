import { FAQContact } from "./contact"
import { FAQ } from "./faq"
import { OuterStyle } from "../style"

export function FAQContainer() {
    const style = OuterStyle()
    
    /*function organizeFAQs(response) {
        let all = []
        for(const title in response) {
            if(!all.filter(e => e.title === title).length > 0) all.push({title: title, sub: []})
            for(var i = 0; i < response[title].length; i++) {
                const tit = all.length - 1
                const category = response[title][i].category
                if(!all[tit].sub.filter(e => e.category === category).length > 0) 
                    all[tit].sub.push({category: category, sub: []})
                
                const cat = all[tit].sub.length - 1
                if(response[title][i].lines === "*" || response[title][i].lines.includes(defaultLine)) {
                    all[tit].sub[cat].sub.push({ question: response[title][i].question, answer: response[title][i].answer })
                }
            }
        }
        return all
    }*/
    
    return (
        <div style={style.container}>
            <FAQContact />
            <FAQ />
        </div>
    )
}