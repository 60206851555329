import { defaultTheme } from "../../../../../config.mjs"
import { THEMES } from "../../../../../data/themes"
import { PreviewStyle } from "../../../style"

export function Carousel({ imageSelection, setImageSelection, imageSet }) {
    const style = PreviewStyle()
    const theme = THEMES[defaultTheme]
    
    return(
        <div style={style.carousel}>
            { imageSet.map((img, id) => {
                return <button 
                    key={id}
                    style={{...style.previewButton, 
                        border: id === imageSelection ? `2px solid ${theme.specialBackground}` : `2px solid ${theme.pageText}`}} 
                    onClick={() => setImageSelection(id)}
                >
                    <img style={style.carouselImage} height="auto" width="auto" alt={'Carousel Preview ' + id} title={'Carousel Preview ' + id} loading="eager" src={img} />
                </button>
            })}
        </div>
    )
}