import { THEMES } from "./data/themes";
import { defaultTheme } from "./config.mjs";
import ScrollToTop from "./hooks/scrollToTop";
import Paths from "./paths";

export default function AppContainer() {
    const theme = THEMES[defaultTheme]
    
    return (
        <div id='app-container' style={{backgroundColor: theme.pageBackground}}>
            <ScrollToTop />
            <Paths />
        </div>
    );
}