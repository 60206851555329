import { ButtonStyle } from "../style"

export function PrecheckButton({ valid, pageCount, currentPage, setCurrentPage, closeModal }) {
    const style = ButtonStyle()

    function getLeftButton() {
        if(currentPage > 0)
            return <button style={{...style.button, ...style.prev}} onClick={() => setCurrentPage(-1)}>Previous</button>                
        return <button style={{...style.button, ...style.prev}} onClick={() => closeModal()}>Close</button>
    }

    function getRightButton() {
        let text
        if(currentPage < pageCount - 1) text = 'Next'
        else text = 'Add to Cart'

        if(valid) return <button style={{...style.button, ...style.good}} onClick={() => setCurrentPage(1)}>{text}</button>
        else return <button style={{...style.button, ...style.bad}} disabled={true}>{text}</button>
    }
    
    return(
        <div style={style.buttonContainer}>
            { getLeftButton() }
            { getRightButton() }
        </div>
    )
}