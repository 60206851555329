import { useParams, Outlet, Navigate } from 'react-router-dom';
import CategoriesPage from '../pages/categories';

export default function CategoryPath() {    
    const { lineID, categoriesID } = useParams()

    const checkCategories = () => {
        if(categoriesID.toLowerCase() !== 'products') return <Navigate to={ '/' } />
        return <CategoriesPage />
    }
    
    return (
        lineID ? <Outlet /> : checkCategories()
    );
}