export default function FeaturePicture({ landingData }) {
    const container = {
        flex: '1 0',
        maxWidth: window.innerWidth < 780 ? "100%" : "50%",
        display: "flex",
        alignItems: "center"
    }

    const picture = {
        maxHeight: "100%",
        maxWidth: "100%"
    }
    
    return (
        <div style={container}>
            <img alt={'Feature'} title={'Feature'} style={picture} height="auto" width="auto" loading="lazy" src={landingData.image} />
        </div>
    )
}