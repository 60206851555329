import { useState } from "react"
import { InputStyle } from "../style"
import { hearAbout } from "../../../data/selects"
import Select from 'react-select'

export default function Contact({page, setPage, moveOn}) {
    const style = InputStyle()
    const [firstNameErr, setFirstNameErr] = useState()
    const [lastNameErr, setLastNameErr] = useState()
    const [emailErr, setEmailErr] = useState()
    const [phoneErr, setPhoneErr] = useState()
    const [heardAbout, setHeardAbout] = useState()

    const phoneMask = () => {
        const phone = document.getElementById('phone')
        const mask = "(xxx) xxx-xxxx"
        let current = phone.value
        current = current.replace(/\D+/g, '')
        
        let result = ''
        let counter = 0
        for(var i = 0; i < mask.length; i++) {
            if(counter >= current.length) break
            if(mask[i] === "x") {
                result += current[counter]
                counter++
            }
            else result += mask[i]
        }
        phone.value = result
    }

    const changePage = () => {
        if(page > 0) setPage(0)
    }

    const handleHeardAbout = (option) => {
        setHeardAbout(option.value)
    }

    const validateContact = () => {
        let good = true
        if(document.getElementById('first-name').value === '') { setFirstNameErr('Required'); good = false }
        if(document.getElementById('last-name').value === '') { setLastNameErr('Required'); good = false }
        if(document.getElementById('email').value === '') { setEmailErr('Required'); good = false }
        else {
            const emailGood = String(document.getElementById('email').value).toLowerCase().match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if(!emailGood) { setEmailErr('Invalid Email Address'); good = false }
        }
        if(document.getElementById('phone').value === '') { setPhoneErr('Required'); good = false }
        else {
            if(document.getElementById('phone').value.length !== 14) { setPhoneErr('Invalid PhoneNumber'); good = false }
        }
        
        if(good) moveOn({
            firstName: document.getElementById('first-name').value,
            lastName: document.getElementById('last-name').value,
            email: document.getElementById('email').value,
            phone: document.getElementById('phone').value,
            extension: document.getElementById('phone-ext').value,
            reference: heardAbout
        })
    }
    
    return (
        <div style={{...style.container, cursor: page > 0 ? 'pointer' : 'default'}} onClick={() => changePage()}>
            <div style={style.header}>Contact Information</div>
            <div style={{...style.form, height: '527px', maxHeight: '527px'}} className={page === 0 ? '' : 'hidden-form'}>
                <div style={style.inputOuter}>
                    <div style={style.inputContainer}>
                        <div style={style.label}>First Name</div>
                        <input style={style.input} id="first-name" placeholder='' aria-label="First Name" className={firstNameErr ? 'err' : ''} type="text" onFocus={() => setFirstNameErr()} autoComplete="given-name" />
                        <div style={style.error}>{firstNameErr}</div>
                    </div>
                    <div style={style.inputContainer}>
                        <div style={style.label}>Last Name</div>
                        <input style={style.input} id="last-name" placeholder='' aria-label="Last Name" className={lastNameErr ? 'err' : ''} type="text" onFocus={() => setLastNameErr()} autoComplete="family-name" />
                        <div style={style.error}>{lastNameErr}</div>
                    </div>
                </div>
                <div style={style.inputOuter}>
                    <div style={style.inputContainer}>
                        <div style={style.label}>Email Address</div>
                        <input style={style.input} id="email" placeholder='' aria-label="Email" className={emailErr ? 'err' : ''} type="text" onFocus={() => setEmailErr()} autoComplete="email" />
                        <div style={style.error}>{emailErr}</div>
                    </div>
                </div>
                <div style={style.inputOuter}>
                    <div style={style.inputContainer}>
                        <div style={style.label}>Phone</div>
                        <input style={style.input} id="phone" placeholder='' aria-label="Phone" className={phoneErr ? 'err' : ''} type="text" onChange={phoneMask} onFocus={() => setPhoneErr()} autoComplete="tel-national" />
                        <div style={style.error}>{phoneErr}</div>
                    </div>
                    <div style={{...style.inputContainer, flex: "1"}}>
                        <div style={style.label}>Phone Ext.</div>
                        <input style={style.input} id="phone-ext" placeholder='' aria-label="Phone Extension" type="text" autoComplete="tel-extension" />
                        <div style={style.error}></div>
                    </div>
                </div>
                <div style={style.inputOuter}>
                    <div style={style.inputContainer}>
                        <div style={style.label}>How did you hear about us?</div>
                        <Select 
                            styles={{
                                control: (base) => ({...base, ...style.item })
                            }}
                            options={hearAbout}
                            onChange={handleHeardAbout}
                            placeholder=""
                            id="heard-about"
                            aria-label={"Heard About"}
                        />
                        <div style={style.error}></div>
                    </div>
                </div>
                <div style={style.button} onClick={validateContact} className="button-hover">Continue</div>
            </div>
        </div>
    )
}