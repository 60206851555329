import { defaultLogo } from "../../../config.mjs";
import { Link, useParams } from "react-router-dom";
import { HeaderStyle } from "../../style";
import { website } from "../../../config.mjs";

export default function Logo() {
    const style = HeaderStyle()
    const {productID} = useParams()
    
    return (
        <Link to={productID ? '/products/' : '/'} style={style.logoContainer}>
            <img height={window.innerWidth < 780 ? '40px' : '60px'} width="auto" alt={`${website} Logo`} title={`${website} Logo`} loading="eager" src={defaultLogo} />
        </Link>
    );
}