export function LoadingPage() {
    const containerStyle = {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "0",
        left: "0"
    }

    return (
        <div style={containerStyle}>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}