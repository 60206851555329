import { Link } from "react-router-dom"
import { SummaryStyle } from "../style"
import { useContext } from "react"
import { CartContext } from "../../../providers/cartProvider"

export function CartSummary() {
    const style = SummaryStyle()
    const cart = useContext(CartContext)
    
    return (
        <div style={style.container}>
            <div style={style.title}>Summary</div>
            <div style={style.allLines}>
                <div style={style.lineContainer}><div>Subtotal</div><div>$ {cart.subTotal.toFixed(2)}</div></div>
                <div style={style.lineContainer}><div>Shipping</div><div>FREE</div></div>
                <div style={style.lineContainer}><div>Estimated Tax</div><div>---</div></div>
                <div style={style.lineContainer}><div>Discounts</div><div>$ 0.00</div></div>
                <div style={style.blankLine} />
                <div style={{...style.lineContainer, fontWeight: "600"}}><div>Total</div><div>$ {cart.subTotal.toFixed(2)}</div></div>
                <div style={style.blankLine} />
            </div>
            { cart.items.length > 0 && <Link to='./checkout' id="gtm-checkout" style={style.checkout} className="button-hover">Checkout</Link> }
            { cart.items.length === 0 && <div style={style.noCheckout}>Checkout</div> }
            <Link to='./products' style={style.shopping} className="secondary-button-hover">Keep Shopping</Link>
        </div>
    )
}