import { AboutUsStyle } from "../../style"

export function AboutUs({ landingData }) {
    const style = AboutUsStyle(landingData)
    
    return(
        <div id={landingData.headerLinkID} style={style.container}>
            <div style={style.header}>{landingData.headerText}</div>
            <div style={style.inner}>
                <div style={style.infoContainer}>
                    <div style={style.littleHeader}>{landingData.subHeader}</div>
                    <div style={style.info}>{landingData.text}</div>
                </div>
                <div style={style.imageContainer}>
                    <img style={style.image} src={landingData.image} alt={landingData.subHeader} title={landingData.subHeader} loading="lazy" width="100%" height="auto" />
                </div>
            </div>
        </div>
    )
}