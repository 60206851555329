import { useContext, useEffect, useState } from "react"
import { PrecartContext } from "../../../../providers/precartProvider"
import { RowSelection } from "./components/rowSelection"
import { FileproOptionsStyle } from "../../style"
import { contactPhone } from "../../../../config.mjs"

export default function FileproOptions({ product }) {
    const precart = useContext(PrecartContext)
    const style = FileproOptionsStyle()
    const [options, setOptions] = useState()

    useEffect(() => {
        setOptions(precart.fileproOptions)
    }, [precart.fileproOptions, setOptions])

    function getContainer(options) {
        if(!product.disabledVehicleOption) {
            if(product.fileproOption && product.fileproOption !== '') return true
            if(precart.fileproOptionsLoading || (options && options.length === 0)) return true
            return false
        }
        return false
    }

    function getFileproOption() {
        if(precart.fileproOptionsLoading) return <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        else {
            if(options) {
                if(options.length === 0) {
                    return <>
                        <div style={style.unavailableTitle}>* No Pattern Available</div>
                        <div style={style.unavailableTextContainer}>
                            We don't currently offer this product for your vehicle, but we are always updating our pattern library and may have one soon.
                            <br /><br />If you wish to make a pattern request, you can contact us at {contactPhone}.
                        </div>
                    </>
                }
                else {
                    if(product.fileproOption === 'rows') return <RowSelection />
                }
            }
            else {
                return <div style={style.subTitle}>Please select the year, make, and model of your vehicle to add to cart.</div>
            }
        }
    }

    function getFileproLabel() {
        if(product.fileproOption === 'rows') return 'Select Seats'
        else {
            if((!options || options.length === 0) && !precart.fileproOptionsLoading) return 'Product Not Available'
        }        
    }
    
    return (
        getContainer(options) ?
        <div style={style.container}>
            <div style={style.title}>{ getFileproLabel() }</div>
            <div style={style.contentContainer}>
                { getFileproOption() }
            </div>
        </div> : null
    )
}