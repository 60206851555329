import { FAQTOC } from "./toc"
import { Questions } from "./questions"
import { FaqStyle } from "../style"

export function FAQ() {
    const style = FaqStyle()
    
    return(
        <div style={style.container}>
            <FAQTOC />
            <Questions />
        </div>
    )
}