import { footerLogo, copywriteLine } from '../../config.mjs'
import { ReactComponent as FacebookIcon } from '../../icons/facebook.svg'
import { ReactComponent as InstagramIcon } from '../../icons/instagram.svg'
import { ReactComponent as YouTubeIcon } from '../../icons/youtube.svg'
import { Link } from 'react-router-dom'
import { FooterStyle } from '../style'

export function Footer({landingFooter = false}) {
    const style = FooterStyle()
    
    return(
        <div style={{...style.container, marginTop: landingFooter ? '0' : '80px' }}>
            <div style={style.inner}>
                <div style={{...style.section, alignItems: "center"}}>
                    <img style={style.logo} alt="small logo" title="small logo" loading="lazy" src={footerLogo} width="150px" height="auto" />
                    <div style={{textAlign: "center"}}>
                        <div style={style.link}>615 W. Knox Rd</div>
                        <div style={style.link}>Tempe, AZ 85284</div>
                    </div>
                    <div style={style.iconContainer}>
                        <Link to='https://www.facebook.com/SeatDecorUSA' target="_blank" rel="noopener" style={style.iconHolder} className='footer-hover' aria-label='Link to Facebook'>
                            <FacebookIcon style={style.icon} />
                        </Link>
                        <Link to='https://www.instagram.com/decorauto/' target="_blank" rel="noopener" style={style.iconHolder} className='footer-hover' aria-label='Link to Instagram'>
                            <InstagramIcon style={style.icon} />
                        </Link>
                        <Link to='https://www.youtube.com/@SeatDecor' target="_blank" rel="noopener" style={style.iconHolder} className='footer-hover' aria-label='Link to YouTube'>
                            <YouTubeIcon style={style.icon} />
                        </Link>
                    </div>
                </div>
            </div>
            <div style={style.line} />
            <div style={style.copyright}>{copywriteLine}</div>
        </div>
    )
}

/* I was told to take this out of the footer, but leaving down here just in case

<div style={style.section}>
    <div style={style.header}>Company</div>
    <Link to='https://www.dashdesigns.com' target="_blank" style={style.link}>Dash Designs Website</Link>
    <Link to='https://dashdesignsdealers.com/index.php' target="_blank" style={style.link}>Dealer Login</Link>
    <Link to='https://www.dashdesigns.com/locator.html' target="_blank" style={style.link}>Dealer Locator</Link>
</div>
<div style={style.section}>
    <div style={style.header}>Useful Links</div>
    <Link to={'/' + landing.landingID + '/contact/'} style={style.link}>Contact Us</Link>
    <Link to={'/' + landing.landingID + '/faq/'} style={style.link}>Frequently Asked Questions</Link>
    <Link to={'/' + landing.landingID + '/privacy/'} style={style.link}>Privacy Policy</Link>
</div>
<div style={style.section}>
    <div style={style.header}>Follow Us</div>
    <Link to='https://www.facebook.com/SeatDecorUSA' target="_blank" style={style.link}>Facebook</Link>
    <Link to='https://www.instagram.com/decorauto/' target="_blank" style={style.link}>Instagram</Link>
    <Link to='https://www.youtube.com/@SeatDecor' target="_blank" style={style.link}>YouTube</Link>
</div>
*/