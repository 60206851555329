// STEPS FOR A NEW WEBSITE //
// Step 1: Edit this config File
// Step 2: Adjust hover variable colors in app.css file
// Step 3: Edit index.html to reflect the new site
// Step 4: Adjust .env to the correct Google API
// Don't forget to edit index.html to reflect site, adjust robots.txt, and change favicon

export const website = "Seat Decor"
export const websiteURL = "https://seatdecor.com"
export const defaultTheme = "white-theme"

export const defaultLogo = '/img/logos/seat-decor-logo-black.webp'
export const footerLogo = '/img/logos/seat-decor-logo-gray.webp'
export const copywriteLine = '© 2024 Seat Décor'

export const landingPage = 'seat-decor'
export const pageProducts = ['premium-seat-covers']
export const upgradedConsoleCost = 28.80
export const discountRate = .15

export const contactPhone = '1-800-843-3274'
export const contactEmail = 'info@seatdecor.com'