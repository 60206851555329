import { PrecheckStyle } from "../style";

export function PrecheckContent({ product, page, vehicle, pageOption, setPageOption }) {
    const style = PrecheckStyle()

    function getImage(option) {
        if(product.prodLine === "430") return '/img/styles/K' + option.Item.substring(1, 4) + '.jpg'
        else {
            if(option.Note.toLowerCase().includes('w/o sensor')) return '/img/styles/sensor-no.jpg'
            if(option.Note.toLowerCase().includes('w/ sensor')) return '/img/styles/sensor.jpg'
        }
        return '/img/styles/default.jpg'
    }

    return (
        page.type !== 'CONSOLE' ?
        <div style={style.inner}>
            <div style={style.rowHeader}>
                WHICH BEST DESCRIBES YOUR {page.type}?
            </div>
            <div style={style.allNotes}>
                { page.optionList.map((option, num) => {
                    return <div key={num} style={{...style.noteContainer, 
                        border: num === pageOption ? `3px solid #008a00` : `1px solid #dddddd`}} onClick={() => setPageOption(num)}>
                        <img style={style.noteImage} alt={option.Item + ' Selection'} src={getImage(option)} />
                        <div style={style.note}>
                            <div style={style.noteTitle}>{option.Note}</div>
                            <div style={style.noteDescription}>
                                Description: {vehicle.year} {vehicle.make} {vehicle.model} {product.type}
                            </div>
                            <div style={style.noteNote}>Item #: {option.Item}</div>
                        </div>
                    </div>
                })}
            </div>
        </div> :
        <div style={style.inner}>
        <div style={style.rowHeader}>
            UPGRADE YOUR CONSOLE COVER?
        </div>
        <div style={style.allNotes}>
            { page.optionList.map((option, num) => {
                return <div key={num} style={{...style.noteContainer, 
                    border: num === pageOption ? `3px solid #008a00` : `1px solid #dddddd`}} onClick={() => setPageOption(num)}>
                    <img style={style.noteImage} alt={option.Description} src={option.Image} />
                    <div style={style.note}>
                        <div style={style.noteTitle}>{option.Note}</div>
                        <div style={style.noteNote}>
                            {option.Description}
                        </div>
                        { option.Item ? <div style={style.noteDescription}>Item #: {option.Item}</div> : null }
                    </div>
                </div>
            })}
        </div>
    </div>
    )
}