import { HeroStyle } from '../../style';
import HeroText from './text';

export default function Hero({ landingData }) {
    const style = HeroStyle(landingData)

    return (
        <div style={style.container}>
            <div style={style.inner}>
                <HeroText landingData={landingData} />
            </div>
        </div>
    )
}