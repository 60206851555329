import { useContext } from 'react';
import ProductPage from '../pages/product';
import { PrecartProvider } from '../providers/precartProvider';
import { ProductsContext } from '../providers/productsProvider';
import { Navigate, useParams } from 'react-router-dom';

export default function ProductPath() {
    const { lineID, productID } = useParams()
    const products = useContext(ProductsContext)
    const lineData = products.lines.filter((line) => line.siteID === lineID)[0]

    const getProduct = () => {
        let good = false
        if(lineData && lineData.products) {
            for(var i = 0; i < lineData.products.length; i++) {
                if(lineData.products[i].siteID === productID) good = true
            }
        }
        if(good) return <PrecartProvider><ProductPage /></PrecartProvider>
        return <Navigate to={'/products/' + lineID + '/'} />
    }
    
    return getProduct()
}