import { ProcessingStyle } from "../style"

export default function Processing() {
    const style = ProcessingStyle()
    
    return (
        <div style={style.container}>
            <div style={style.text}>Processing Your Order</div>
            <div className="lds-hourglass"></div>
        </div>
    )
}