import { ReactComponent as FaqIcon } from '../../../icons/faq.svg'
import { Link } from "react-router-dom";
import { HeaderStyle } from "../../style";

export default function Faq({ setHover }) {
    const style = HeaderStyle()
    
    return (
        <Link to={'/faq/'} style={style.iconContainer} className={'header-hover'} onMouseOver={() => setHover(false)}>
            <FaqIcon style={style.icon} fill='none' />
            <div style={style.iconText}>FAQ</div>
        </Link>
    );
}