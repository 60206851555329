import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ProductsProvider } from './providers/productsProvider';
import { FontProvider } from './providers/fontProvider';
import { CartProvider } from './providers/cartProvider';
import AppContainer from './Container';

const queryClient = new QueryClient()

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ProductsProvider>
                <FontProvider>
                    <CartProvider>
                        <AppContainer />
                    </CartProvider>
                </FontProvider>
            </ProductsProvider>
        </QueryClientProvider>
    );
}

export default App;