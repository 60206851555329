import { useContext, useEffect } from "react";
import { Header } from "../../components/header";
import { TOC } from "../../components/toc";
import { LineTitle } from "./components/title";
import { Swatch } from "./components/swatch";
import { Footer } from "../../components/footer";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { website, websiteURL } from "../../config";
import { ProductsContext } from "../../providers/productsProvider";
import { SwatchStyle } from "./style";

export default function LinePage() {
    const navigate = useNavigate()
    const style = SwatchStyle()
    const {lineID} = useParams()
    const products = useContext(ProductsContext)
    const lineData = products.lines.filter((line) => line.siteID === lineID)[0]

    useEffect(() => {
        if(lineData.products.length === 1) {
            navigate('/products/' + lineID + '/' + lineData.products[0].siteID + '/', { replace: true })
        }
    }, [navigate, lineData, lineID])
    
    return (
        <>
            <Helmet>
                <title>{lineData.categoryName} - {website}</title>
                <meta name="description" content={lineData.websiteDescription} />
                <link rel="canonical" href={websiteURL + '/products/' + lineID} />
            </Helmet>
            <Header />
            <TOC />
            <LineTitle title={lineData.pageHeader} />
            <div style={style.swatchContainer}>
                { lineData.products.map((product, id) => {
                    return <Swatch key={id} productDetails={product} specify={id} />
                })}
            </div>
            <Footer />
        </>
    );
}