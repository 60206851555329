import { Routes, Route } from 'react-router-dom';
import LandingPath from './landingPath';
import CategoryPath from './categoryPath';
import LinePath from './linePath';
import ProductPath from './productPath';
import Page404 from '../pages/404';

export default function Paths() {
    return (
        <Routes>
            <Route path="/" element={<LandingPath />}>
                <Route path=":categoriesID" element={<CategoryPath />}>
                    <Route path=":lineID" element={<LinePath />}>
                        <Route path=":productID" element={<ProductPath />}>
                            <Route path="*" element={<Page404 />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}