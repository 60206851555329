import { TitleStyle } from "../style"

export function CategoryTitle({individual = null}) {
    const style = TitleStyle()
    
    return (
        <h1 style={individual ? style.individualContainer : style.container}>
            { individual ? individual : 'Select a Product' }
        </h1>
    )
}