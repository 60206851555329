import { Header } from "../../components/header";
import Info404 from "./info";

export default function Page404() {
    return (
        <>
            <Header />
            <Info404 />
        </>
    );
}