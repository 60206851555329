import { useState } from "react";
import { Link } from "react-router-dom";
import { StarRating } from "../../../components/stars";
import ImageZoom from "../../../hooks/imageZoom";
import { SwatchStyle } from "../style";
import { THEMES } from "../../../data/themes";
import { defaultTheme } from "../../../config.mjs";

export function Swatch({ productDetails, specify, url }) {
    const style = SwatchStyle()
    const theme = THEMES[defaultTheme]
    const [select, setSelect] = useState(0)
    
    return (
        <div style={style.container}>
            <Link to={'./' + productDetails.siteID + '?a=' + select}>
                <ImageZoom src={productDetails.colorOptions && productDetails.colorOptions.length > 0 ? productDetails.colorOptions[select].images[0] : productDetails.images[0]} />
            </Link>
            { productDetails.colorOptions && productDetails.colorOptions.length > 0 &&
                <div style={style.optionContainer}>
                { productDetails.colorOptions.map((opt, id) => {
                    if(id < 5 || (id === 5 && productDetails.colorOptions.length === 6))
                        return <div style={{...style.optionBorder, borderColor: select === id ? theme.specialBackground : "transparent"}} key={id}>
                            <div style={{...style.option, backgroundImage: `url(${opt.preview})`}} onClick={() => setSelect(id)} />
                            </div>
                    else {
                        if(id === 5 && productDetails.colorOptions.length > 6)
                            return <Link to={'./' + url + '?a=0'} style={style.optionBorder} key={id}>
                                <div style={{...style.option, backgroundColor: "rgba(0,0,0,.8)"}} key={id}>
                                    <div style={style.number}>+{productDetails.colorOptions.length - 5}</div>
                                </div>
                            </Link>
                        else return null
                    }
                })}
            </div> }
            <div>
                <Link to={'./' + productDetails.siteID + '?a=' + select} style={style.name}>{productDetails.name}</Link><br />
                <Link to={'./' + productDetails.siteID + '?a=' + select} style={style.tagline}>{productDetails.tagline}</Link>
            </div>
            <Link to={'./' + productDetails.siteID + '?a=' + select} style={style.priceContainer}>
                <div style={style.price}>${productDetails.cost}</div>
                <StarRating rating={productDetails.rating} specify={specify} isSmall={true}  invertTheme={true} />
                <div style={style.reviews}>({productDetails.reviews})</div>
            </Link>
            <Link to={'./' + productDetails.siteID + '?a=' + select} style={style.button} className='secondary-button-hover'>Get Started</Link>
        </div>
    )
}