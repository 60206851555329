import { useState } from "react";
import Logo from "./components/logo";
import ShoppingCart from "./components/cart";
import ContactUs from "./components/contact";
import Faq from "./components/faq";
import { CartPanel } from "../cartPanel";
import { HeaderStyle } from "../style";

export function Header() {
    const style = HeaderStyle()
    const [hover, setHover] = useState(false)

    return (
        <div style={style.border}>
            <div style={style.container}>
                <Logo />
                <div style={style.iconHolder}>
                    <Faq setHover={setHover} />
                    <ContactUs setHover={setHover} />
                    <ShoppingCart hover={hover} setHover={setHover} />
                </div>
                <CartPanel hover={hover} setHover={setHover} />
            </div>
        </div>
    );
}