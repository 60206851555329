import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { THEMES } from "../../data/themes";
import { defaultTheme } from "../../config.mjs";

export function LandingComponent(landingData) { 
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: "100vw",
            maxWidth: "100vw",
            padding: "80px 5vw",
            display: "flex",
            flexDirection: fonts.mobile ? "column" : landingData.reverse ? "row-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "48px",
            scrollMarginTop: fonts.mobile ? "82px" : "92px",
            backgroundColor: landingData.backgroundColor
        },
        innerContainer: {
            width: '100vw',
            maxWidth: '1700px',
            padding: "0 5vw",
            display: "flex",
            flexDirection: fonts.mobile ? "column" : landingData.reverse ? "row-reverse" : "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "48px",
        },
        infoContainer: {
            width: fonts.mobile ? "100%" : "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        contentContainer: {
            flex: "1 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
        }
}}

export function HeaderStyle(landingData) { 
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            zIndex: "20",
            top: "0",
            backgroundColor: landingData.header.backgroundColor
        },
        inner: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: fonts.mobile ? "15px 4vw" : "10px 4vw",
            transitionDuration: ".2s",
            width: "100%",
            maxWidth: "1700px"
        },
        logoContainer: {
            padding: "4px 0",
            cursor: "pointer",
            height: "92px",
            display: "flex",
            alignItems: "center"
        },
        linkContainer: {
            display: "flex",
            gap: "2vw"
        },
        link: {
            fontSize: fonts.mobile ? "12px" : "14px",
            fontWeight: "400",
            textTransform: "uppercase",
            letterSpacing: ".01em",
            padding: "8px 1vw",
            cursor: "pointer",
            color: landingData.header.textColor,
            textDecoration: 'none'
        }
}}

export function HeroStyle(landingData) {
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: "100vw",
            height: fonts.mobile ? "85vh" : "100vh",
            backgroundImage: `url(${landingData.image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            position: "relative"
        },
        inner: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100vw",
            height: fonts.mobile ? "85vh" : "100vh",
            background: `linear-gradient(90deg, ${landingData.fadeColor} 0%, ${landingData.fadeColor} 33%, rgba(0,0,0,0) 100%)`,
            display: "flex",
            justifyContent: fonts.mobile ? "center" : "flex-start",
            alignItems: "center",
            padding: fonts.mobile ? "82px 6vw" : "184px 6vw 92px",
            paddingLeft: fonts.mobile ? '6vw' : 'max(6vw, calc((100vw - 1400px) / 2)'
        },
        textContainer: {
            width: "100%",
            maxWidth: "650px",
            display: "flex",
            flexDirection: "column",
            gap: fonts.mobile ? "20px" : "40px",
            color: landingData.textColor,
            textAlign: fonts.mobile ? "center" : "left"
        },
        mobileLogo: {
            marginBottom: "60px"
        },
        header: {
            ...fonts.hero,
            width: "100%",
            maxWidth: "700px",
            textTransform: "uppercase"
        },
        subText: {
            ...fonts.large,
            width: "100%",
            maxWidth: "700px",
        },
        buttonContainer: {
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },
        button: {
            ...fonts.h3,
            padding: "16px 42px",
            display: "flex",
            justifyContent: "center",
            textDecoration: "none",
            alignItems: "center",
            border: `3px solid ${landingData.textColor}`,
            borderRadius: "10px",
            textTransform: "uppercase",
            letterSpacing: ".1em",
            color: landingData.textColor,
            cursor: "pointer",
            transitionDuration: ".1s"
        }
    }
}

export function CarouselStyle() {
    const fonts = useContext(FontContext)
    
    return {
        slideOuter: {
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
        },    
        slideInner: {
            padding: "48px 32px 60px",
            background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 50%, rgba(0,0,0,.8) 100%)"
        },    
        slideHeader: {
            color: "#ffffff",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "28px",
            marginBottom: "12px",
            textTransform: "uppercase"
        },      
        slideText: {
            color: "#dadada",
            fontWeight: "500",
            lineHeight: "18px",
            fontSize: "14px"
        },      
        slideLink: {
            fontSize: "12px",
            border: "2px solid #c2c2c2",
            marginTop: "24px",
            padding: "10px 20px",
            display: "inline-block",
            textDecoration: "none",
            textTransform: "uppercase",
            color: "#717171",
            fontWeight: "500",
            background: "#ffffff",
            borderRadius: "24px",
            transitionDuration: "0.4s"
        },
        moreSwipe: {
            width: "100%", 
            textAlign: "center", 
            color: "#000000", 
            fontSize: "12px", 
            fontWeight: '500', 
            position: 'relative', 
            bottom: '0px',
            display: fonts.mobile ? 'block' : 'none'
        }
    }
}

export function CarouselInfoStyle(landingData) {
    const fonts = useContext(FontContext)
    
    return {
        infoSmallText: {
            textTransform: "uppercase",
            letterSpacing: "1.5px",
            fontSize: "16px",
            color: landingData.textColor
        },
        infoHeader: {
            textTransform: "capitalize",
            letterSpacing: "0.8px",
            fontWeight: "800",
            fontSize: fonts.mobile ? "50px" : window.innerWidth > 1175 ? "55px" : "40px",
            backgroundColor: landingData.highlightColor,
            backgroundImage: `linear-gradient(45deg, ${landingData.highlightColor}, #000000)`
        },
        infoLine: {
            display: "block",
            background: "#005baa",
            height: "4px",
            width: "100px",
            border: "none",
            margin: "18px 0 30px 0",
            backgroundColor: landingData.highlightColor
        },
        infoText: {
            fontSize: "16px",
            lineHeight: "24px",
            color: landingData.textColor
        },
        infoLink: {
            display: "block",
            width: "fit-content",
            textDecoration: "none",
            textTransform: "uppercase",
            fontWeight: "500",
            borderRadius: "50px",
            transitionDuration: "0.4s",
            marginTop: "34px",
            padding: "10px 30px",
            backgroundColor: landingData.highlightColor,
            color: landingData.backgroundColor
        }
    }
}

export function FeatureStyle(landingData) {
    const fonts = useContext(FontContext)
    
    return {
        wordsContainer: {
            width: "100%",
            padding: fonts.mobile ? "60px 2vw 20px" : "60px 30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: fonts.mobile ? "center" : "left",
        },
        textHeader: {
            fontSize: "50px",
            fontWeight: "800",
            width: "100%",
            paddingRight: fonts.mobile ? "0" : "40px",
            paddingBottom: fonts.mobile ? "5.5%" : "4.5%",
            color: landingData.textColor,
        },
        textSub: {
            ...fonts.large,
            width: "100%",
            paddingBottom: fonts.mobile ? "5.5%" : "3%",
            color:landingData.textColor
        },
        textDescription: {
            ...fonts.standard,
            width: "100%",
            paddingBottom: "22px",
            color:landingData.textColor
        },
        buttonContainer: {
            padding: "12px 0",
            alignSelf: fonts.mobile ? "center" : "flex-start",
        },
        button: {
            backgroundColor: landingData.textColor,
            fontSize: "12px",
            fontWeight: "500",
            color: landingData.backgroundColor,
            textDecoration: "none",
            textTransform: "uppercase",
            padding: "8px 25px",
            lineHeight: "18px",
            borderRadius: "30px",
            cursor: "pointer",
            transitionDuration: ".2s",
            display: "flex",
            flex: "0 0 auto",
            alignItems: "center",
            borderWidth: "2px",
            borderStyle: "solid",
            
        },
        arrow: {
            marginTop: "-3px", 
            marginLeft: "10px", 
            fontWeight: "700",
            transitionDuration: ".2s"
        }
    }
}

export function ReviewsStyle(landingData) {
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)

    return {
        container: {
            width: "100%",
            padding: fonts.mobile ? "60px 4vw" : "100px 40px",
            scrollMarginTop: fonts.mobile ? "82px" : "92px",
            backgroundColor: landingData.useStyleB ? theme.componentBackground : theme.pageBackground, 
            color: landingData.useStyleB ? theme.componentText : theme.pageText
        },
        header: {
            ...fonts.h1,
            fontWeight: "300",
            width: "100%",
            textAlign: "center",
            paddingBottom: "40px",
            color: landingData.useStyleB ? theme.componentHeader : theme.pageHeader
        },
        inner: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "50px 5vw",
            justifyContent: "center",
            alignItems: "center"
        },
        reviewContainer: {
            width: "100%",
            maxWidth: "900px",
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            gap: fonts.mobile ? "20px" : "30px",
            alignItems: "center"
        },
        textContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            textAlign: fonts.mobile ? "center" : "left"
        },
        text: {
            ...fonts.standard
        },
        name: {
            ...fonts.h4
        }
    }
}

export function AboutUsStyle(landingData) {
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)

    return {
        container: {
            width: "100%",
            padding: fonts.mobile ? "60px 4vw" : "100px 40px",
            scrollMarginTop: fonts.mobile ? "82px" : "92px",
            backgroundColor: landingData.useStyleB ? theme.componentBackground : theme.pageBackground, 
            color: landingData.useStyleB ? theme.componentText : theme.pageText
        },
        header: {
            ...fonts.h1,
            fontWeight: "300",
            width: "100%",
            textAlign: "center",
            paddingBottom: "40px",
            color: landingData.useStyleB ? theme.componentHeader : theme.pageHeader
        },
        inner: {
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            gap: "80px",
            justifyContent: "center"
        },
        infoContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: "100%",
            maxWidth: "500px"
        },
        littleHeader: {
            ...fonts.large,
            textAlign: fonts.mobile ? "center" : "left"
        },
        info: {
            ...fonts.standard,
            lineHeight: "24px",
            textAlign: fonts.mobile ? "center" : "left"
        },
        imageContainer: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "350px"
        }
    }
}