import { useContext } from "react";
import { useParams } from "react-router-dom";

import { ProductsContext } from "../../providers/productsProvider";
import { FontContext } from "../../providers/fontProvider";
import { website, websiteURL } from "../../config.mjs";

import { Header } from "../../components/header";
import { TOC } from "../../components/toc";
import { Description } from "./components/description";
import Details from "./components/details";
import { Footer } from "../../components/footer";
import { Helmet } from "react-helmet-async";
import { ContainerStyle } from "./style";
import Preview from "./components/preview";
import Options from "./components/options";
import VehicleSelection from "./components/vehicleSelection";
import FileproOptions from "./components/fileproOptions";
import CartButton from "./components/cartButton";
import { Precheck } from "../precheck";
import { PrecartContext } from "../../providers/precartProvider";

export default function ProductPage() {
    const fonts = useContext(FontContext)
    const precart = useContext(PrecartContext)

    const {lineID, productID} = useParams()

    const style = ContainerStyle()
    const products = useContext(ProductsContext)
    const lineData = products.lines.filter((line) => line.siteID === lineID)[0]
    const productData = lineData.products.filter((product) => product.siteID === productID)[0]
    
    return (
        <>
            <Helmet>
                <title>{productData.name} Custom {productData.type} - {website}</title>
                <meta name="description" content={`Shop for custom ${productData.type}s at ${website}. The highest quality products at the best prices. Free Shipping! Order today.`} />
                <link rel="canonical" href={websiteURL + '/products/' + lineID + '/' + productID} />
            </Helmet>
            <Header />
            <TOC />
            { fonts.mobile ? 
                <div style={style.mobileContainer}>
                    <Description product={productData} />
                    <Preview product={productData} />
                    <Options product={productData} />
                    <VehicleSelection />
                    <FileproOptions product={productData} />
                    <CartButton product={productData} />
                    <Details product={productData} />
                </div> : 
                <div style={style.desktopContainer}>
                    <div style={style.leftContainer}>
                        <Preview product={productData} />
                        <Details product={productData} />
                    </div>
                    <div style={style.rightContainer}>
                        <Description product={productData} />
                        <Options product={productData} />
                        <VehicleSelection />
                        <FileproOptions product={productData} />
                        <CartButton product={productData} />
                    </div>
                </div>
            }
            { precart.showPrecheck && <Precheck product={productData} /> }
            <Footer />
        </>
    );
}