import { Header } from "../../components/header"
import { FAQContainer } from "./components/container"
import { Footer } from "../../components/footer"
import { TOC } from "../../components/toc"
import { Helmet } from "react-helmet-async"
import { website, websiteURL } from "../../config.mjs"

export default function FAQPage() {
    return (
        <>
            <Helmet>
                <title>Frequently Asked Questions - {website}</title>
                <meta name="description" content={`Frequently Asked Questions for ${website}`} />
                <link rel="canonical" href={websiteURL + '/faq'} />
            </Helmet>
            <Header />
            <TOC faq />
            <FAQContainer />
            <Footer />
        </>
    )
}