import { PreviewStyle } from "../../../style"

export function PreviewImage({ image }) {
    const style = PreviewStyle()
    
    return (
        <div style={style.preview}>
            <img style={style.previewImage} width="auto" height="auto" alt='Fabric Preview' title='Fabric Preview' loading="eager" src={image} />
        </div>
    )
}