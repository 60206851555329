import { createContext, useState, useEffect, useMemo } from 'react';

export const FontContext = createContext({});

export function FontProvider({ children }) {
    const [mobile, setMobile] = useState()
    const [hero, setHero] = useState({})
    const [h1, setH1] = useState({})
    const [h2, setH2] = useState({})
    const [h3, setH3] = useState({})
    const [h4, setH4] = useState({})
    const [standard, setStandard] = useState({})
    const [small, setSmall] = useState({})
    const [large, setLarge] = useState({})
    
    //choose the screen size 
    const handleResize = () => {
        switch(true) {
            case window.innerWidth < 500: 
                setMobile(true)
                setHero({ fontSize: "44px", fontWeight: "700", lineHeight: "56px" })
                setH1({ fontSize: "27px", fontWeight: "500", lineHeight: "33px" })
                setH2({ fontSize: "24px", fontWeight: "500", lineHeight: "33px" })
                break
            case window.innerWidth < 780: 
                setMobile(true)
                setHero({ fontSize: "56px", fontWeight: "700", lineHeight: "65px" })
                setH1({ fontSize: "25px", fontWeight: "500", lineHeight: "33px" })
                setH2({ fontSize: "24px", fontWeight: "500", lineHeight: "33px" })
                break
            case window.innerWidth < 1040: 
                setMobile(false)
                setHero({ fontSize: "60px", fontWeight: "700", lineHeight: "70px" })
                setH1({ fontSize: "25px", fontWeight: "500", lineHeight: "33px" })
                setH2({ fontSize: "24px", fontWeight: "500", lineHeight: "33px" })
                break
            default:
                setMobile(false)
                setHero({ fontSize: "80px", fontWeight: "700", lineHeight: "75px" })
                setH1({ fontSize: "35px", fontWeight: "500", lineHeight: "40px" })
                setH2({ fontSize: "28px", fontWeight: "500", lineHeight: "40px" })
        }
        setH3({ fontSize: "16px", fontWeight: "600", lineHeight: "20px" })
        setH4({ fontSize: "14px", fontWeight: "600", lineHeight: "18px" })
        setLarge({ fontSize: "18px", fontWeight: "400", lineHeight: "28px" })
        setStandard({ fontSize: "14px", fontWeight: "400", lineHeight: "20px"})
        setSmall({ fontSize: "12px", fontWeight: "400", lineHeight: "16px"})
    }

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize()
    }, [])
 
    const memoizedValue = useMemo(
      () => ({
        mobile: mobile,
        hero: hero,
        h1: h1,
        h2: h2,
        h3: h3,
        h4: h4,
        standard: standard,
        small: small,
        large: large
      }), [mobile, hero, h1, h2, h3, h4, standard, small, large]
    );
  
    return <FontContext.Provider value={memoizedValue}>{children}</FontContext.Provider>;
}