import { useState, useEffect, useContext } from "react"
import { InputStyle } from "../style"
import { shippingType, states } from "../../../data/selects"
import Select from 'react-select'
import { CartContext } from "../../../providers/cartProvider"

export default function Shipping({page, setPage, moveOn}) {
    const style = InputStyle()
    const cart = useContext(CartContext)
    const [address1Err, setAddress1Err] = useState()
    const [cityErr, setCityErr] = useState()
    const [stateErr, setStateErr] = useState()
    const [zipErr, setZipErr] = useState()
    const [state, setState] = useState()
    const [destination, setDestination] = useState('Residential')

    useEffect(() => {
        if(document.readyState === "complete") initAutocomplete()
        else window.addEventListener("load", initAutocomplete)
        // eslint-disable-next-line
    }, [])

    const initAutocomplete = () => {
        const autocomplete = new window.google.maps.places.Autocomplete(
            (document.getElementById('address1')), {
                types: ['address'],
                fields: ["address_components", "types"],
                componentRestrictions: { country: ["us"] },
            }
        )
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace()
            let currentState
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                  case "street_number": { document.getElementById('address1').value = component.long_name;  break; }
                  case "route": { document.getElementById('address1').value += ' ' + component.short_name; break; }
                  case "postal_code": { document.getElementById('zip').value = component.short_name; break; }
                  case "locality": { document.getElementById('city').value = component.long_name; break}
                  case "administrative_area_level_1": { setState(component.short_name); currentState = component.short_name; break; }
                  default: break
                }
            }
            setAddress1Err(); setCityErr(); setZipErr(); setStateErr()
            checkTaxReady(currentState)
            document.getElementById('address2').focus()
        })
    }

    const changePage = () => {
        if(page > 1) setPage(1)
    }

    const handleState = (option) => {
        setState(option.value)
        setStateErr()
        checkTaxReady(option.value)
    }

    const handleDestination = (option) => {
        setDestination(option.value)
    }

    function checkTaxReady(currentState) {
        if(document.getElementById('city').value !== '' && document.getElementById('state').value !== '' && document.getElementById('zip').value !== '')
            cart.setShippingValues({
                company: document.getElementById('company').value,
                address1: document.getElementById('address1').value,
                address2: document.getElementById('address2').value,
                city: document.getElementById('city').value,
                state: currentState || state,
                zip: document.getElementById('zip').value,
                destination: destination
            })
    }

    const validateShipping = () => {
        let good = true
        if(document.getElementById('address1').value === '') { setAddress1Err('Required'); good = false }
        if(document.getElementById('city').value === '') { setCityErr('Required'); good = false }
        if(!state || state === '') { setStateErr('Required'); good = false }
        if(document.getElementById('zip').value === '') { setZipErr('Required'); good = false }
        else {
            if(document.getElementById('zip').value.length < 5) { setZipErr('Invalid Zip Code'); good = false}
        }
        
        if(good) moveOn({
            company: document.getElementById('company').value,
            address1: document.getElementById('address1').value,
            address2: document.getElementById('address2').value,
            city: document.getElementById('city').value,
            state: state,
            zip: document.getElementById('zip').value,
            destination: destination
        })
    }
    
    return (
        <div style={{...style.container, cursor: page > 1 ? 'pointer' : 'default'}} onClick={() => changePage()}>
            <div style={style.header}>Shipping Information</div>
            <div style={{...style.form, height: '527px', maxHeight: '527px'}} className={page === 1 ? '' : 'hidden-form'}>
                <div style={style.inputOuter}>
                    <div style={style.inputContainer}>
                        <div style={style.label}>Company (optional)</div>
                        <input style={style.input} id="company" placeholder='' aria-label="Company" type="text" autoComplete="organization" />
                        <div style={style.error}></div>
                    </div>
                </div>
                <div style={style.inputOuter}>
                    <div style={style.inputContainer}>
                        <div style={style.label}>Address</div>
                        <input style={style.input} id="address1" placeholder='' aria-label="Address" className={address1Err ? 'err' : ''} type="text" onFocus={() => setAddress1Err()} />
                        <div style={style.error}>{address1Err}</div>
                    </div>
                    <div style={{...style.inputContainer, flex: "1"}}>
                        <div style={style.label}>Apt/Ste</div>
                        <input style={style.input} id="address2" placeholder='' aria-label="Address 2"type="text" />
                    </div>
                </div>
                <div style={style.inputOuter}>
                    <div style={style.inputContainer}>
                        <div style={style.label}>City</div>
                        <input style={style.input} id="city" placeholder='' aria-label="City" className={cityErr ? 'err' : ''} type="text" onFocus={() => setCityErr()} autoComplete="address-level2" />
                        <div style={style.error}>{cityErr}</div>
                    </div>
                    <div style={{...style.inputContainer, flex: "1"}}>
                        <div style={style.label}>State</div>
                        <Select 
                            styles={{control: (base) => ({...base, ...style.item })}}
                            options={states}
                            onChange={handleState}
                            value={states.filter(option => option.value === state)}
                            placeholder=""
                            id="state"
                            aria-label={"State"}
                        />
                        <div style={style.error}>{stateErr}</div>
                    </div>
                </div>
                <div style={style.inputOuter}>
                    <div style={{...style.inputContainer, flex: "1"}}>
                        <div style={style.label}>Zip</div>
                        <input style={style.input} id="zip" placeholder='' aria-label="Zip" className={zipErr ? 'err' : ''} type="text" onChange={() => checkTaxReady()} onFocus={() => setZipErr()} autoComplete="postal-code" />
                        <div style={style.error}>{zipErr}</div>
                    </div>
                    <div style={style.inputContainer}>
                        <div style={style.label}>Destination Type</div>
                        <Select 
                            styles={{control: (base) => ({...base, ...style.item })}}
                            options={shippingType}
                            onChange={handleDestination}
                            defaultValue={{ value: 'Residential', label: 'Residential' }}
                            placeholder=""
                            id="destination"
                            aria-label={"Destination"}
                        />
                    </div>
                </div>
                <div style={style.button} className='button-hover' onClick={validateShipping}>Continue</div>
            </div>
        </div>
    )
}