import { Link } from "react-router-dom"
import { FeatureStyle, LandingComponent } from "../../style"

export default function FeatureInfo({ landingData }) {
    const component = LandingComponent(landingData)
    const style = FeatureStyle(landingData)
    
    return(
        <div style={component.infoContainer}>
            <div style={style.wordsContainer}>
                <div style={style.textHeader}>{landingData.headerText}</div>
                <div style={style.textSub}>{landingData.subHeaderText}</div>
                <div style={style.textDescription}>{landingData.subText}</div>
                { landingData.buttonText && landingData.link && <div style={style.buttonContainer}>
                    <Link style={style.button} className='h-bg-darkgray'
                    to={'.' + landingData.link}>
                        <div>{landingData.buttonText}</div><div style={style.arrow}>→</div>
                    </Link>
                </div> }
            </div>
            
        </div>
    )
}