import { Header } from "../../components/header"
import { Footer } from "../../components/footer"
import { website, websiteURL } from "../../config.mjs"
import { Helmet } from "react-helmet-async"
import { Bag } from "./components/bag"
import { CartSummary } from "./components/summary"
import { CartPageStyle } from "./style"

export default function CartPage() {
    const style = CartPageStyle()
    
    return (
        <>
            <Helmet>
                <title>Shopping Cart</title>
                <meta name="description" content={website + ` Shopping Cart Page. Order today by visiting us at ` + websiteURL + '!'} />
                <link rel="canonical" href={websiteURL + '/shopping-cart'} />
            </Helmet>
            <Header />
            <div style={style.container}>
                <Bag />
                <CartSummary />
            </div>
            <Footer />
        </>
    )
}