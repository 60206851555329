import { useParams, Outlet, Navigate } from 'react-router-dom';
import LinePage from '../pages/line';
import { useContext } from 'react';
import { ProductsContext } from '../providers/productsProvider';

export default function LinePath() {
    const products = useContext(ProductsContext)
    const { lineID, productID } = useParams()

    const checkLine = () => {
        let found = false
        for(var i = 0; i < products.lines.length; i++) {
            if(products.lines[i].siteID === lineID) found = true
        }
        if(found) return <LinePage />
        return <Navigate to={'/products/'} />
    }
    
    return (
        productID ? <Outlet /> : checkLine()
    );
}