import { ReactComponent as UsersIcon } from '../../../icons/users.svg'
import { ReactComponent as CubesIcon } from '../../../icons/cubes.svg'
import { ReactComponent as EmailIcon } from '../../../icons/email.svg'
import { ReactComponent as PhoneIcon } from '../../../icons/cell-phone.svg'
import { Link } from "react-router-dom"
import { contactEmail, contactPhone, website } from "../../../config.mjs"
import { ContactStyle } from "../style"

export function FAQContact() {
    const style = ContactStyle()
    
    return(
        <div style={style.container}>
            <div  style={style.inner}>
                <div style={style.title}>{website} Difference</div>
                <div style={style.line} />
                <div style={style.expContainer}>
                    <UsersIcon style={style.icon} />
                    <div>
                        <div style={style.expHeader}>We're the experts</div>
                        <div style={style.expText}>Ask us anything. We are the professionals!</div>
                    </div>
                </div>
                <div style={style.expContainer}>
                    <CubesIcon style={style.icon} />
                    <div style={{width: "100%"}}>
                        <div style={style.expHeader}>Largest Selection</div>
                        <div style={style.expText}>If you don't find the part, we'll get it for you!</div>
                    </div>
                </div>
            </div>
            <div style={style.inner}>
                <div style={style.title}>Have a question? Ask the pros</div>
                <div style={style.line} />
                <div style={style.expContainer}>
                    <EmailIcon style={style.icon} />
                    <Link to={`mailto:${contactEmail}`} style={style.link} className='page-hover'>
                        <div style={style.expHeader}>Email Us</div>
                        <div style={style.expText}>{contactEmail}</div>
                    </Link>
                </div>
                <div style={style.expContainer}>
                    <PhoneIcon style={style.icon} />
                    <Link to={`tel:${contactPhone}`} style={style.link} className='page-hover'>
                        <div style={style.expHeader}>Call Us</div>
                        <div style={style.expText}>Give us a call now!<br />{contactPhone}</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}