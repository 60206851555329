import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"

export function ModalStyle() {
    const fonts = useContext(FontContext)
    
    return {
        background: {
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, .5)",
            display: "flex",
            justifyContent: "center",
            zIndex: "200"
        },
        container: {    
            position: "relative",
            zIndex: "100",
            width: "100%",
            maxWidth: "1024px",
            padding: fonts.mobile ? "0 12px 24px" : "0 48px 12px",
            height: "100vh",
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        }
    }
}

export function HeaderStyle() {
    const fonts = useContext(FontContext)
    
    return {
        header: {
            width: "100%",
            display: "flex",
            padding: fonts.mobile ? "32px 0" : "0 36px",
            gap: fonts.mobile ? "14px" : "24px",
            alignItems: "center",
            borderBottom: '1px solid #dddddd'
        },
        vehiclePicture: {
            width: fonts.mobile ? "130px" : "250px",
            height: "auto"
        },
        title: {
            fontSize: fonts.mobile ? "18px" : "28px",
            lineHeight: fonts.mobile ? "24px" : "40px",
            fontWeight: "700",
            color: "#333333",
            
        },
        descriptors: {
            fontSize: fonts.mobile ? "14px" : "18px",
            lineHeight: fonts.mobile ? "20px" : "28px",
            fontWeight: "400",
            color: "#666666"
        }
    }
}

export function PrecheckStyle() { 
    const fonts = useContext(FontContext)
    
    return {
        inner: {
            flex: "1 0", 
            overflow: "hidden", 
            display: "flex", 
            flexDirection: "column"
        },
        rowHeader: {
            ...fonts.h3,
            marginTop: "24px",
            color: "#333333"
        },
        allNotes: {
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            margin: "18px 0",
            overflowY: "auto",
            padding: "4px 4px 30px"
        },
        noteContainer: {
            width: "100%",
            padding: fonts.mobile ? "8px 12px" : "14px 28px",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            gap: fonts.mobile ? "12px" : "28px"
        },
        noteImage: {
            height: fonts.mobile ? "70px" : "100px",
            width: "auto"
        },
        note: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        noteTitle: {
            fontSize: fonts.mobile ? "14px" : "18px",
            fontWeight: "500",
            paddingBottom: fonts.mobile ? "4px" : "8px",
            textTransform: "uppercase",
            color: "#333333"
        },
        noteDescription: {
            display: fonts.mobile ? "none" : "block",
            fontSize: fonts.mobile ? "12px" : "14px",
            color: "#666666"
        },
        noteNote: {
            fontSize: fonts.mobile ? "12px" : "14px",
            color: "#666666"
        }
    }
}

export function ButtonStyle() {
    const fonts = useContext(FontContext)
    
    return {
        buttonContainer: {
            display: "flex",
            padding: "16px 0",
            flexDirection: fonts.mobile ? "column-reverse" : "row",
            justifyContent: "space-between",
            gap: "16px",
            marginBottom: fonts.mobile ? '10vh' : '0'
        },
        button: {
            ...fonts.h4,
            fontWeight: "700",
            textTransform: "uppercase",
            height: "48px",
            width: fonts.mobile ? "100%" : "190px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "0",
            borderRadius: "4px"
        },
        good: {
            backgroundColor: "#219653",
            color: "#ffffff",
            cursor: "pointer"
        },
        bad: {
            backgroundColor: "#ededed",
            color: "#ffffff",
        },
        prev: {
            border: "1px solid #424242",
            backgroundColor: "#ffffff",
            color: "#424242",
            cursor: "pointer"
        }
    }
}